.body-content-container {
    // height: 85vh;
}
.blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
}

@media (min-width: 992px) {
    body .actor-detail-container {
        padding-top: 0;
        padding-left: 19rem;
    }
}
#sideNav .navbar-nav .nav-item .nav-link {
    font-weight: 800;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
}
@media (min-width: 992px) {
    #sideNav {
        text-align: center;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 19rem;
        height: 100vh;
    }
    #sideNav .navbar-brand {
        display: flex;
        margin: auto auto 0;
        padding: 0.5rem;
    }
    #sideNav .navbar-brand .img-profile {
        max-width: 15rem;
        max-height: 15rem;
        border: 0.8rem solid rgba(255, 255, 255, 0.2);
    }
    #sideNav .navbar-collapse {
        display: flex;
        align-items: flex-start;
        flex-grow: 0;
        width: 100%;
        margin-bottom: auto;
    }
    #sideNav .navbar-collapse .navbar-nav {
        flex-direction: column;
        width: 100%;
    }
    #sideNav .navbar-collapse .navbar-nav .nav-item {
        display: block;
    }
    #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
        display: block;
    }
}

.profile-rank-india {
    // color: #e13a14;
    // color: #0000ff;
    color: #ffff;
}
.profile-name-india {
    // color: #e13a14;
    color: #170a8a;
}
.profile-bg-india {
    // background-color: #b1d7fc;
    // background-color:#ff9933;
    background-color: #170a8a;
}
.avatar-size {
    width: 40px;
    height: 40px;
}
.avatar-mediu-size {
    width: 250px;
    height: 250px;
}
.avatar-mobile-border{
    border: 0.5rem solid rgba(255, 255, 255, 0.2);
  }
  .sidenave-caption{
      color: #f1cc73;
  }