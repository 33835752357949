.country-name {
    border-bottom: 2px solid #e63213;
    font-size: 2rem;
    font-weight: bolder;
}
.juror-name {
    border-bottom: 2px solid #3fa051;
    font-size: 1.5rem;
    font-weight: bolder;
}
.juror-country {
    font-size: 1rem;
}