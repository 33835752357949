// .vertical-center {
//     min-height: 100%; /* Fallback for browsers do NOT support vh unit */
//     min-height: 100vh; /* These two lines are counted as one :-)       */
//     display: flex;
//     flex: 1;
//     align-items: center;
//     background-image: linear-gradient(176deg, #22313f 70%, #f4f4f4 calc(70% + 2px));
// }

.custome-form-control {
    border: 2px solid #ced4da;
    font-size: 1.3em;
}
.avatar-medium {
    width: 20vh;
    height: 20vh;
    margin: auto;
}
