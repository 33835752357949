.testimonial-container{
    background-color: #4e6484;
    padding-top: 5rem;
    padding-bottom: 4rem;
}

.testimonial {
    font-size: 2rem;
}
.testimonial-author {
    font-size: 1.5rem;
}
.testimonial-color {
    color: white;
}
.testimonial-hide-color {
    color: #4e6484;
}

.top-left-0 {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s;
}
  
.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 1s;
}
  
@media (max-width: 375px) {
    .testimonial-author {
      float: right;
    }
}
  