.social-icons a:last-child {
    margin-right: 0;
  }
  /* .social-icons a:hover {
    background-color: #e13a14;
  } */
  /* .social-icons a {
    display: inline-block;
    height: 2.5rem;
    width: 2.5rem;
    background-color: #495057;
    color: #fff !important;
    border-radius: 100%;
    text-align: center;
    font-size: 1rem;
    line-height: 2.5rem;
    margin-right: 1rem;
  } */
  
  .social-icon{
    font-size: 1.25rem;
  }
  // svg:hover {
  //   fill: #e13a14;
  // }
  
  
// .social-icons-container{
//   width: 35px;
//   height: 35px;
//   align-items: center;
//   display: flex;
//   justify-content: center;
//   border:solid 1px #6c757d ;
// }
// .social-icons-container:hover{
//   border:solid 1px #e13a14;
// }
  