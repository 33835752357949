
.img-container{
    overflow: hidden;
    position: relative;
  }
  // .img-container:hover{
  //   -webkit-transform: scale(1.025);
  //   -ms-transform: scale(1.025);
  //   transform: scale(1.025);
  // }
  .img-container:hover .vew-bio{
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .vew-bio
    {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 7px;
    text-align: center;
    background: #e13a14;
    color: #fff;
    pointer-events: none;
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    -o-transition: transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease,-webkit-transform .3s ease;
    width:200px;
    margin: auto;;
  }