@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

@font-face {
  font-family: "Futura PT W01 Light";
  src: url("../../assets/Fonts/1059746/9ad55860-bbe2-4e51-be58-08b45bdda354.woff2") format("woff2"),
    url("../../assets/Fonts/1059746/eceb6e13-403b-4d2b-af74-c05bc9c2535e.woff") format("woff");
}

html,
body,
#root {
  height: 100%;
  font-family: "Futura PT W01 Light";
}
body {
  overflow-x: hidden;
}
p {
  font-size: 1.2rem;
}
.mainContainer {
  // height: 100%;
  display: flex;
  flex-direction: column;
}
.btn-larger-text {
  font-size: 1.5rem;
  line-height: 1;
}
.dd-valign.dropdown-toggle::after {
  vertical-align: 0.15em;
}
.fontWeight-800{
  font-weight: 800;
}
.fontWeight-700{
  font-weight: 700;
}
.logo-landing {
  width: 90px;
  height: 90px;
  // border: solid 1px black;
  border-radius: 50%;
  // background: white;
  // padding: 5px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.65) !important;
}
.logo-list-landing {
  // width: 10rem;
  height: 48px;
  // box-shadow: 0 0 1rem rgba(0, 0, 0, 0.65) !important;
}
.logo-landing-bio {
  width: 80px;
  height: 80px;
  // border: solid 1px black;
  border-radius: 50%;
  // background: white;
  // padding: 5px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.65) !important;
}
.logo-container {
  position: absolute;
  left: 50%;
}
.logo-container-left {
  position: absolute;
  left: 120px;
}
.logo-subcontainer {
  position: relative;
  left: -50%;
  z-index: 999;
}
.ie-issue-logo {
  position: relative;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .logo-container {
    position: absolute;
    top: 0;
    margin-top: 10px;
  }
  .logo-subcontainer-ie {
    left: 117%;
  }
  .ie-issue-logo {
    position: static;
  }
}
.relative {
  position: relative;
}
.header-bg-left {
  background: #2714bf;
  background: -webkit-linear-gradient(to right, #2714bf, #09af57);
  background: linear-gradient(to right, #2714bf, #09af57);
  // height:60px;

  background-image: linear-gradient(to right, #2714bf, #09af57);
}
.header-bg-right {
  background: #f93232;
  background: -webkit-linear-gradient(to right, #f93232, #f8b13e);
  background: linear-gradient(to right, #f93232, #f8b13e);
  // height:60px;

  background-image: linear-gradient(to left, #f93232, #f8b13e);
}

// Mobile media
@media only screen and (max-width: 600px) {
  .logo-landing {
    width: 70px;
    height: 70px;
  }
  .logo-list-landing {
    width: 6rem;
  }
  .logo-container-left {
    position: absolute;
    left: 95px;
  }
  .list-dsc-img-container img {
    height: 90vh !important;
  }
  p {
    font-size: 1rem;
  }
  .home-list-img-container-1,
  .home-list-img-container-2 {
    min-height: 150px !important;
  }
  // .quote-img-container{
  //   img{
  //     height: 30vh!important;
  //   }
  // }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Futura PT W01 Light";
  font-weight: 500;
  text-transform: uppercase;
}
.remove-text-transform {
  text-transform: none;
}
.govt-country-link {
  a:hover {
    text-decoration: none;
  }
  a {
    border-bottom: 2px solid;
  }
}
.black-font {
  color: #000000;
}
.grid-company {
  font-size: 75%;
}
.h1-4rem {
  font-size: 4rem;
}
// h2 {
//   font-size: 3.5rem;
// }
.h3-2-half-rem {
  font-size: 2.5rem;
}

.ag-icon {
  color: #edc709;
}
.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}
.ag-header-cell {
  background-color: #4f5151;
  color: #fff;
}
// .ag-body-viewport.ag-layout-normal {
//   overflow-y: hidden!important;
// }

.ag-root-wrapper-body.ag-layout-normal {
  height: 100% !important;
  // width: 100%!important ;
}
// .ag-header-cell-label .ag-header-cell-text {
//   white-space: normal !important;
// }

.bio-back-button {
  color: #e13a14;
}

.bio-resume-field {
  font-size: 1.2rem;
}
.custome-margin-right {
  margin-right: 20px;
}

.custome-margin-left {
  margin-left: 20px;
}
.ln-ht-small {
  line-height: 1.2;
}
.edit-newsimg-container img {
  height: 200px;
  object-fit: cover;
}
.edit-newsimg-container {
  text-align: center;
}
.display-newsimg-container img {
  // width:200px;
  height: 200px;
  object-fit: cover;
}
.display-newsimg-container {
  text-align: center;
  background-size: cover;
}
.list-dsc-img-container img {
  // width:200px;
  height: 320px;
  object-fit: cover;
}
.list-dsc-img-container {
  text-align: center;
  background-size: cover;
}
.hero-down-arrow {
  position: absolute;
  bottom: 0;
  z-index: 10;
  color: #939d9d;
  height: 2em;
  width: 2em;
}
// STart of cover page carousel
.zIndex-1 {
  z-index: 1;
}
.zIndex-6 {
  z-index: 6;
}
.zIndex-7 {
  z-index: 7;
}
.right-0 {
  right: 0;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.bottom-0 {
  bottom: 0;
}
.Hero-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.65);
}
.Hero {
  height: calc(100vh - 74px);
}
.ht-100 {
  height: calc(100vh);
}
.ht-90 {
  height: calc(90vh);
}
.no-caps {
  text-transform: none;
}
.HomepagePhotoSlider-image{
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 60% 5%;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
}
#coverImageContainer {
  background-image: url("../../assets/cover/indiaspora-lists-bg.jpg");
}
#list-home-phil-2021-bg-image {
  background-image: url("../../assets/img/phil-leader-list.jpg");
}
// End of cover page carousel

// .quote-img-container {
//   background-image: url("../../assets/img/s3.png");
//   background-size: cover;
//   background-repeat: no-repeat;
// }
// .quote-img-container{
//   text-align: center;
//   background-size: cover;
// }
.display-bio-img-container img {
  width: 200px;
  height: auto;
}
.display-bio-img-container {
  text-align: center;
}

.news-launch {
  margin-top: -2px;
}

.table-short-icon {
  color: #e13a14;
}

.under-line {
  text-decoration: underline;
}

.zero-line-height {
  line-height: 0;
}

.signUp {
  color: #fff;
  background-color: #000080;
}
.social-fo-color {
  color: #f1cc73;
  :hover {
    color: rgb(51, 166, 211);
  }
}
a.social-fo-color:hover {
  color: rgb(51, 166, 211);
}
.lst-descritption,
.home-lst-descritption {
  // background-color: #81c3c9 ;
  color: #000;
  font-weight: 600;
}

// .avatar-title-text{
//   font-size: .9rem;
// }
.hand-cursor {
  cursor: pointer;
}

// slider specific

.slider-container {
  opacity: 0.75;
}
.slider-container:hover {
  opacity: 1;
}

.slider-desktop-container {
  height: 90vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.slider-desktop-container-child {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: -15px;
  overflow: auto;
}
.color-dark {
  color: #434141;
}
.slider-up,
.slider-down {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 0;
  color: white;
  z-index: 10;
  cursor: pointer;
  transition: opacity 0.6s;
  *background-color: #ccc;
  backface-visibility: hidden;
}

.slider-down {
  bottom: 0;
}
.slider-up {
  top: 0;
}
.slider-img-container {
  text-align: center;
}
.slider-img-container img {
  width: 100px;
  height: auto;
}
// mobile slider
.slider-mobile-container {
  // height: 100px;
  overflow: hidden;
}
.slider-mobile-container-child {
  // height:90px; /* 40px - more place for scrollbar, is hidden under parent box */
  padding: 5px, 0, 5px 0;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hide;
  -webkit-overflow-scrolling: touch;
}
.slider-mobile-card {
  display: inline-block;
  width: 90px;
  height: auto;
  line-height: 90px;
  text-align: center;
  // background:#ddd;
}
.slider-mobile-card + .slider-mobile-card {
  margin-left: 5px;
}
.slider-mobile-country-card {
  display: inline-block;
  width: 90px;
  height: auto;
  line-height: 50px;
  text-align: center;
  // background:#ddd;
}
.slider-mobile-country-card + .slider-mobile-country-card {
  margin-left: 5px;
}
.loader-container {
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 80%;
    margin: 1.75rem auto;
  }
  .modal-dialog.modal-small {
    margin: 1.75rem auto;
    max-width: max-content;
  }
}

// For map Anotation
// .country-bg-canada .annotation-note-bg {
//   height: 23px;
//   fill: #ff0000;
//   fill-opacity: 1;
// }
// .country-bg-usa .annotation-note-bg {
//   height: 23px;
//   fill: #1d1de0;
//   fill-opacity: 1;
// }
// .country-bg-suriname .annotation-note-bg {
//   height: 23px;
//   width: 56px;
//   fill: #f8b13e;
//   fill-opacity: 1;
// }

// .country-bg-ireland .annotation-note-bg {
//   height: 23px;
//   width: 45px;
//   fill: #008000;
//   fill-opacity: 1;
// }
// .country-bg-unitedKingdom .annotation-note-bg {
//   height: 23px;
//   width: 88px;
//   fill: #0b52f7;
//   fill-opacity: 1;
// }
// .country-bg-singapore .annotation-note-bg {
//   height: 23px;
//   width: 60px;
//   fill: #d42441;
//   fill-opacity: 1;
// }
// .country-bg-australia .annotation-note-bg {
//   height: 23px;
//   width: 55px;
//   fill: #f8b13e;
//   fill-opacity: 1;
// }
// .country-bg-sriLanka .annotation-note-bg {
//   height: 23px;
//   width: 55px;
//   fill: #9c1536;
//   fill-opacity: 1;
// }
// .country-bg-malaysia .annotation-note-bg {
//   height: 23px;
//   width: 60px;
//   fill: #fff52d;
//   fill-opacity: 1;
// }
// .country-bg-southAfrica .annotation-note-bg {
//   height: 23px;
//   width: 70px;
//   fill: #09af57;
//   fill-opacity: 1;
// }
// .country-bg-trinidadAndTobago .annotation-note-bg {
//   height: 23px;
//   width: 101px;
//   fill: #100105;
//   fill-opacity: 1;
// }

// .country-bg-mauritius .annotation-note-bg {
//   height: 23px;
//   width: 101px;
//   fill: #211780;
//   fill-opacity: 1;
// }

// .country-bg-newZealand .annotation-note-bg {
//   height: 23px;
//   width: 101px;
//   fill: #090644;
//   fill-opacity: 1;
// }
// .country-bg-portugal .annotation-note-bg {
//   height: 23px;
//   width: 101px;
//   fill: #f93232;
//   fill-opacity: 1;
// }
// .country-bg-fiji .annotation-note-bg {
//   height: 23px;
//   width: 101px;
//   fill: #3aa7de;
//   fill-opacity: 1;
// }
// .country-bg-guyana .annotation-note-bg {
//   height: 23px;
//   fill: #1d1de0;
//   fill-opacity: 1;
// }
.annotation-note-title {
  font-size: 12px;
  font-weight: 600;
}
// For map Anotation

// For design
.home-list-title {
  border-bottom: 2px solid #1e3fa2;
}
.home-list-container {
  img {
    height: 140px;
  }
}
.home-list-container-3 {
  img {
    height: 100%;
  }
}

.home-list-img-container-1 {
  min-height: 200px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  content: "";
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  img {
    height: auto;
    object-fit: unset;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }
}

.home-list-img-container-2 {
  min-height: 200px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  content: "";
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  img {
    height: auto;
    object-fit: unset;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }
}
.quote-img-container {
  img {
    height: 50vh;
  }
  .__react_modal_image__modal_container {
    img {
      height: 100vh;
    }
  }
}
// .home-list-container-4,
.home-list-container-5 {
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
}

.govt-leader-list {
  .flag-section {
    img {
      width: 100px;
      height: 40px;
    }
  }
}

.govt-list-desc {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/indiaspora-prod.appspot.com/o/metadata%2FUntitled%20design-bio.jpg?alt=media&token=f027d658-3fb1-4707-838a-f4ff10667ab8);
}
.chalo-color {
  color: #2180ff;
}
.give-color {
  color: #29c30d;
}

//gov side column
.side-navbar-scroll {
  position: absolute;
  // overflow-y: scroll;
  overflow-y: auto;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}


@media screen and (max-height: 700px) and (min-width: 995px) {
  #sideNav .navbar-brand .img-profile {
    max-width: 10rem;
    max-height: 10rem;
    border: 0.8rem solid rgba(255, 255, 255, 0.2);
  }
  .sidenave-caption{
    font-size: 18px;

  }
  .js-scroll-trigger {
    span{
      font-size: 21px;
    }
  }
  .mb-5.zero-line-height {
    line-height: 0;
    margin-bottom: 1rem !important;
  }
 
}
